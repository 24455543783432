import { formField } from "src/utils/constants"
import {
  MDWNOValues,
  createEditFieldNames,
  dailyFieldNames,
  monthlyFieldNames,
  systemDefaultFieldNames,
  weekDays,
  weeklyFieldNames,
  yearlyFieldNames
} from "../CreateEditMultiEventOutput"
import { TimeFieldType } from "../../Types"

export const fileCSOptions = {
  Default: "System Default",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Yearly: "Yearly",
  Reservation: "Reservation End"
}

export const FcsDetailsFields = {
  fileCreationSchedule: {
    fieldType: formField.radio,
    props: {
      name: createEditFieldNames.FileCreationSchedule.name,
      formControl: {
        radioGroup: {
          row: true,
          formControlLabels: [
            {
              value: fileCSOptions.Default,
              label: "System_Default"
            },
            {
              value: fileCSOptions.Daily,
              label: "Daily"
            },
            {
              value: fileCSOptions.Weekly,
              label: "Weekly"
            },
            {
              value: fileCSOptions.Monthly,
              label: "Monthly"
            },
            {
              value: fileCSOptions.Yearly,
              label: "Yearly"
            },
            {
              value: fileCSOptions.Reservation,
              label: "Reservation_End"
            }
          ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
        }
      }
    },
    childCols: { sm: 12 },
    triggerValidationFields: [
      { name: systemDefaultFieldNames.SDMinStore.name },
      { name: systemDefaultFieldNames.SDMaxStore.name },
      { name: createEditFieldNames.Time.name },
      { name: createEditFieldNames.StartDate.name },
      { name: createEditFieldNames.EndDate.name },
      { name: dailyFieldNames.DayRadio.name },
      { name: dailyFieldNames.DayInterval.name },
      { name: dailyFieldNames.WeekDayRadio.name },
      { name: weeklyFieldNames.WeeklyInterval.name },
      { name: weeklyFieldNames.Sunday.name },
      { name: weeklyFieldNames.Monday.name },
      { name: weeklyFieldNames.Tuesday.name },
      { name: weeklyFieldNames.Wednesday.name },
      { name: weeklyFieldNames.Thursday.name },
      { name: weeklyFieldNames.Friday.name },
      { name: weeklyFieldNames.Saturday.name },
      { name: monthlyFieldNames.DayRadio.name },
      { name: monthlyFieldNames.DayNumber.name },
      { name: monthlyFieldNames.DayMonthInterval.name },
      { name: monthlyFieldNames.WeekRadio.name },
      { name: monthlyFieldNames.WeekNumber.name },
      { name: monthlyFieldNames.WeekDay.name },
      { name: monthlyFieldNames.WeekMonthInterval.name },
      { name: yearlyFieldNames.YearInterval.name },
      { name: yearlyFieldNames.DayMonthRadio.name },
      { name: yearlyFieldNames.DayMonth.name },
      { name: yearlyFieldNames.DayMonthDayNumber.name },
      { name: yearlyFieldNames.WeekMonthRadio.name },
      { name: yearlyFieldNames.WeekMonthWeekNumber.name },
      { name: yearlyFieldNames.WeekMonthWeekDay.name },
      { name: yearlyFieldNames.WeekMonth.name }
    ]
  }
}

export const TimeFields: TimeFieldType = {
  time: {
    fieldType: formField.time,
    props: {
      name: createEditFieldNames.Time.name,
      label: createEditFieldNames.Time.label,
      required: true
    },
    parentCols: { className: "mt-1" },
    childCols: { sm: 2 }
  }
}

export const renderType = {
  heading: "heading",
  field: "field"
}

export const WeekNumberOptions = [
  { value: MDWNOValues.First, label: "First", default: true },
  { value: MDWNOValues.Second, label: "Second" },
  { value: MDWNOValues.Third, label: "Third" },
  { value: MDWNOValues.Fourth, label: "Fourth" }
]

export const WeekDayOptions = [
  { value: weekDays.Sunday, label: weekDays.Sunday },
  { value: weekDays.Monday, label: weekDays.Monday },
  { value: weekDays.Tuesday, label: weekDays.Tuesday },
  { value: weekDays.Wednesday, label: weekDays.Wednesday, default: true },
  { value: weekDays.Thursday, label: weekDays.Thursday },
  { value: weekDays.Friday, label: weekDays.Friday },
  { value: weekDays.Saturday, label: weekDays.Saturday }
]
