import { SelectionOptions, sourceForCombinedCountOptions } from "./fieldOptions"
import functionNames, {
  numberWithRequired,
  stringWithRequired
} from "./functionNames"

const functionTypes = functionNames
const SIDEBAR_ITEM = "sidebarItem"

const mathFunctions = [
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Add,
    content: "Add",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: {
          ...numberWithRequired
        },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Subtract,
    content: "Subtract",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...numberWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Multiply,
    content: "Multiply",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          ...stringWithRequired,
          ...{ oneOf: SelectionOptions.map(i => i.value) }
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: { ...numberWithRequired },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Divide,
    content: "Divide",
    parameters: [],
    inputType: [
      {
        type: "radio",
        name: "parameter1",
        options: SelectionOptions,
        validation: {
          oneOf: SelectionOptions.map(i => i.value),
          validate: value => {
            const isValidNumber =
              typeof value === "number" && (value > 0 || value < 0)
            const isValidString =
              typeof value === "string" && value.trim().length > 0
            return isValidNumber || isValidString
          },
          error: "Value must be either a valid number or a non-empty string."
        }
      },
      {
        type: "inputText",
        label: "Constant*",
        name: "parameter2",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 0.0000001,
              error: "Value should be more than 0"
            }
          }
        },
        conditions: { name: "parameter1" }
      },
      {
        type: "autoComplete",
        name: "parameter3",
        label: "Another Source*",
        options: sourceForCombinedCountOptions,
        validation: {
          ...stringWithRequired,
          ...{
            oneOf: [...[""], ...sourceForCombinedCountOptions.map(i => i.value)]
          }
        },
        conditions: { name: "parameter1" }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Round,
    content: "Round",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 0,
              error: "Value should be more than 0"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Floor,
    content: "Floor",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 0,
              error: "Value should be more than 0"
            }
          }
        }
      }
    ]
  },
  {
    type: SIDEBAR_ITEM,
    function: functionTypes.Ceil,
    content: "Ceil",
    parameters: [],
    inputType: [
      {
        type: "inputText",
        label: "Decimal Places*",
        name: "parameter1",
        validation: {
          ...numberWithRequired,
          ...{
            min: {
              min: 0,
              error: "Value should be more than 0"
            }
          }
        }
      }
    ]
  }
].sort((a, b) => {
  if (a.function > b.function) {
    return 1
  } else if (b.function > a.function) {
    return -1
  } else {
    return 0
  }
})

export default mathFunctions
