import { Grid, Divider, Alert, AlertTitle } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import ForwardIcon from "@mui/icons-material/Forward"
import React, { useEffect, useState } from "react"
import MiniHeader from "src/components/MiniHeader/MiniHeader"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { PATH } from "src/constants/paths"
import {
  setScheduleMappingData,
  setScheduleReset,
  setMappingPayloadData
} from "src/redux/slices/scheduleSlice"
import { useTranslation } from "react-i18next"
import { Translates } from "src/i18n/i18n"
import { encryptBulkColumns, getTranslations } from "src/utils/helper"
import BulkDataSelectField from "../Component/BulkDataSelectField"
import BulkUploadButtons from "../Component/BulkUploadButtons"
import LocationAndScheduleTopHeadings from "../Component/LocationAndScheduleTopHeadings"
import { PromptTypes } from "src/utils/constants"

const ScheduleBulkDataMapping = ({ setPage }) => {
  const dispatch = useAppThunkDispatch()
  const history = useHistory()
  const [alertError, setAlertError] = React.useState({ title: "", content: "" })
  const [bulkData, setBulkData] = useState([] as any)
  const scheduleFileData: any = useSelector(
    (state: RootState) => state.schedule.scheduleFileData
  )
  const scheduleMappingData = useSelector(
    (state: RootState) => state.schedule.scheduleMappingData
  )

  const csvScheduleColumnList = useSelector(
    (state: RootState) => state.schedule.csvScheduleColumnList
  )
  const { t } = useTranslation<any>()
  const [fileDataString, setFileDataString] = useState("" as any)
  const getColumnHeader = value => {
    if (csvScheduleColumnList.includes(value)) {
      return true
    } else {
      return false
    }
  }
  const getIndividualScheduleDetail = val => {
    if (val === "siteId") {
      if (scheduleMappingData.siteId) {
        return scheduleMappingData.siteId
      } else return getColumnHeader("Site ID") ? "Site ID" : ""
    } else if (val === "scheduledDate") {
      if (scheduleMappingData.scheduledDate) {
        return scheduleMappingData.scheduledDate
      } else return getColumnHeader("Scheduled Date") ? "Scheduled Date" : ""
    } else if (val === "scheduledTime") {
      if (scheduleMappingData.scheduledTime) {
        return scheduleMappingData.scheduledTime
      } else return getColumnHeader("Scheduled Time") ? "Scheduled Time" : ""
    } else if (val === "configurationName") {
      if (scheduleMappingData.configurationName) {
        return scheduleMappingData.configurationName
      } else
        return getColumnHeader("Configuration Name") ? "Configuration Name" : ""
    } else if (val === "numberofDevices") {
      if (scheduleMappingData.numberofDevices) {
        return scheduleMappingData.numberofDevices
      } else
        return getColumnHeader("Number Of Devices") ? "Number Of Devices" : ""
    } else {
      if (scheduleMappingData.notes) {
        return scheduleMappingData.notes
      } else return getColumnHeader("Notes") ? "Notes" : ""
    }
  }
  const [scheduleDetails, setScheduleDetails] = useState({
    siteId: getIndividualScheduleDetail("siteId"),
    scheduledDate: getIndividualScheduleDetail("scheduledDate"),
    scheduledTime: getIndividualScheduleDetail("scheduledTime"),
    configurationName: getIndividualScheduleDetail("configurationName"),
    numberofDevices: getIndividualScheduleDetail("numberofDevices"),
    notes: getIndividualScheduleDetail("notes")
  } as any)

  useEffect(() => {
    getFileData()
  }, [])

  const getFileData = () => {
    setFileDataString(scheduleFileData)
    let keys: any = csvScheduleColumnList
    setBulkData(keys)
    setScheduleDetails({
      siteId: getIndividualScheduleDetail("siteId"),
      scheduledDate: getIndividualScheduleDetail("scheduledDate"),
      scheduledTime: getIndividualScheduleDetail("scheduledTime"),
      configurationName: getIndividualScheduleDetail("configurationName"),
      numberofDevices: getIndividualScheduleDetail("numberofDevices"),
      notes: getIndividualScheduleDetail("notes")
    })
  }

  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )

  const handleNoCustomerId = () => {
    if (!customerId) {
      setTimeout(() => {
        history.push(PATH.SCHEDULE)
      }, 3000)
    }
  }

  const handleChangeSchedule = (e, id) => {
    setAlertError({
      title: "",
      content: ""
    })
    if (id === 1) {
      setScheduleDetails({
        ...scheduleDetails,
        siteId: e.target.value
      })
    } else if (id === 2) {
      setScheduleDetails({
        ...scheduleDetails,
        scheduledDate: e.target.value
      })
    } else if (id === 3) {
      setScheduleDetails({
        ...scheduleDetails,
        scheduledTime: e.target.value
      })
    } else if (id === 4) {
      setScheduleDetails({
        ...scheduleDetails,
        configurationName: e.target.value
      })
    } else if (id === 5) {
      setScheduleDetails({
        ...scheduleDetails,
        numberofDevices: e.target.value
      })
    } else {
      setScheduleDetails({
        ...scheduleDetails,
        notes: e.target.value
      })
    }
  }
  const handleValidation = () => {
    setAlertError({
      title: "",
      content: ""
    })
    dispatch(setScheduleMappingData(scheduleDetails))
    sendPayload()
  }
  const sendPayload = () => {
    const COLUMNS = [
      {
        dataAttribute: "Site ID",
        csvAttribute: scheduleDetails.siteId
      },
      {
        dataAttribute: "Scheduled Date",
        csvAttribute: scheduleDetails.scheduledDate
      },
      {
        dataAttribute: "Scheduled Time",
        csvAttribute: scheduleDetails.scheduledTime
      },
      {
        dataAttribute: "Configuration Name",
        csvAttribute: scheduleDetails.configurationName
      },
      {
        dataAttribute: "Number Of Devices",
        csvAttribute: scheduleDetails.numberofDevices
      },
      {
        dataAttribute: "Notes",
        csvAttribute: scheduleDetails.notes
      }
    ].map(i =>
      i.csvAttribute === PromptTypes.NONE ? { ...i, csvAttribute: "" } : i
    )
    const payloadToAdd = {
      idCustomer: customerId,
      IsConfirmed: false,
      continueStatus: null,
      columns: encryptBulkColumns(COLUMNS),
      fileData: fileDataString
    }
    dispatch(setMappingPayloadData(payloadToAdd))
    setPage("page3")
  }
  const handlePreviousClick = () => {
    setPage("page1")
  }
  const handleCancelClick = () => {
    dispatch(setScheduleReset())
    history.push(PATH.SCHEDULE)
  }
  const saveDisable = () => {
    if (
      scheduleDetails.siteId === "" ||
      scheduleDetails.scheduledDate === "" ||
      scheduleDetails.configurationName === ""
    ) {
      return true
    } else {
      return false
    }
  }
  // istanbul ignore next
  const showError = () => {
    return (
      <Alert
        severity="error"
        icon={<WarningIcon />}
        className="custom-alert grey"
      >
        <AlertTitle className="custom-alert__title">
          {alertError?.title}
        </AlertTitle>
        <p>{alertError?.content}</p>
      </Alert>
    )
  }

  const bulkDataWithNone = [PromptTypes.NONE, ...bulkData]
  return (
    <Grid container>
      <MiniHeader
        headerTitle={getTranslations(t, Translates.UPLOAD_SCHEDULES)}
        multiCustomer={handleNoCustomerId}
      />
      <Grid container className="inner-view">
        <Grid item xs={12} className="main-container">
          <LocationAndScheduleTopHeadings />
          <Grid container className="ml-2 mt-2">
            <Grid container>
              <Grid item sm={3} className="align-items-center d-flex ">
                <label>{getTranslations(t, Translates.Site_ID)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  data-testid="siteId"
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.siteId}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={1}
                  bulkData={bulkData}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="pb-3 pt-1">
              <Divider />
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Scheduled_Date)}*</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.scheduledDate}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={2}
                  bulkData={bulkData}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="align-items-center d-flex ">
                <label>{getTranslations(t, Translates.Scheduled_Time)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.scheduledTime}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={3}
                  bulkData={bulkDataWithNone}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>
                  {getTranslations(t, Translates.Configuration_Name)}*
                </label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.configurationName}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={4}
                  bulkData={bulkData}
                />
              </Grid>
            </Grid>
            <Grid container className="mb-3">
              <Grid item sm={3} className="d-flex align-items-center">
                <label>
                  {getTranslations(t, Translates.Number_of_Devices)}*
                </label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.numberofDevices}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={5}
                  bulkData={bulkData}
                  FormControlClass=" mb-0"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container>
              <Grid item sm={3} className="d-flex align-items-center">
                <label>{getTranslations(t, Translates.Notes)}</label>
              </Grid>
              <Grid item sm={1} className="d-flex align-items-center">
                <ForwardIcon className="mr-1 blue-text" />
              </Grid>
              <Grid item sm={4}>
                <BulkDataSelectField
                  label={getTranslations(t, Translates.CSV_Column_Header)}
                  value={scheduleDetails.notes}
                  defaultValue={""}
                  handleOnChange={handleChangeSchedule}
                  fieldIndex={6}
                  bulkData={bulkDataWithNone}
                  FormControlClass=" mb-0"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="py-3">
              <Divider />
            </Grid>
            <Grid container className="justify-content-start mt-4">
              <Grid item xs={12} sm={8}>
                {alertError?.title !== "" ? <>{showError()}</> : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BulkUploadButtons
              previousBLabel={getTranslations(t, Translates.Previous)}
              handlePreviousClick={handlePreviousClick}
              cancelBLabel={getTranslations(t, Translates.Cancel)}
              handleCancelClick={handleCancelClick}
              nextBLabel={getTranslations(t, Translates.NextValidation)}
              handleNextClick={handleValidation}
              saveDisable={saveDisable}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(ScheduleBulkDataMapping)
