import { formField } from "src/utils/constants"
import { renderType } from "./FcsOptions"
import { dailyFieldNames } from "../CreateEditMultiEventOutput"

export const dailyRadioOptions = {
  everyDay: "everyDay",
  everyWeekDay: "everyWeekDay"
}
export const dailyFields = [
  [
    {
      type: renderType.field,
      fieldType: formField.radio,
      width: "22px",
      props: {
        name: dailyFieldNames.DayRadio.name,
        formControl: {
          radioGroup: {
            row: true,
            formControlLabels: [
              {
                value: dailyRadioOptions.everyDay,
                label: ""
              }
            ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
          }
        }
      },
      resetFieldNames: [{ name: dailyFieldNames.WeekDayRadio.name, value: "" }]
    },
    { type: renderType.heading, heading: "Every", className: "ml-1" },
    {
      type: renderType.field,
      fieldType: formField.text,
      width: "80px",
      className: "ml-2",
      disableCondition: {
        name: dailyFieldNames.DayRadio.name,
        value: dailyRadioOptions.everyDay
      },
      props: {
        name: dailyFieldNames.DayInterval.name,
        label: dailyFieldNames.DayInterval.label,
        required: true
      }
    },
    { type: renderType.heading, heading: "day_s", className: "ml-2" }
  ],
  [
    {
      type: renderType.field,
      fieldType: formField.radio,
      width: "22px",
      props: {
        name: dailyFieldNames.WeekDayRadio.name,
        formControl: {
          radioGroup: {
            row: true,
            formControlLabels: [
              {
                value: dailyRadioOptions.everyWeekDay,
                label: ""
              }
            ].map(i => ({ ...i, radio: { size: "medium" }, className: "mr-5" }))
          }
        }
      },
      resetFieldNames: [{ name: dailyFieldNames.DayRadio.name, value: "" }],
      triggerValidationFields: [{ name: dailyFieldNames.DayInterval.name }]
    },
    {
      type: renderType.heading,
      heading: dailyFieldNames.WeekDayRadio.label,
      className: "ml-1"
    }
  ]
]
