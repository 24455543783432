import * as yup from "yup"
import {
  atLeastOneFieldIsRequired,
  numberTypeErr,
  oneOfErr,
  pastTimingErr,
  requiredErr
} from "src/utils/constants"
import { createEditReportFieldNames } from "src/configurationPortal/pages/Reports/Utils/Constants/CreateEditReportConstant"

import {
  YDMOValues,
  createEditFieldNames,
  dailyFieldNames,
  monthlyFieldNames,
  systemDefaultFieldNames,
  weeklyFieldNames,
  yearlyFieldNames
} from "./Constants/CreateEditMultiEventOutput"
import {
  FcsDetailsFields,
  WeekDayOptions,
  WeekNumberOptions,
  fileCSOptions
} from "./Constants/FileCreationSchedule/FcsOptions"
import { destinationValidation } from "src/configurationPortal/pages/Reports/Utils/Validations/CreateEditReportValidation"
import { generalSettingValidation as outputGeneralSettingValidation } from "./../../../configurationPortal/pages/Outputs/Utils/Validation"
import { dailyRadioOptions } from "./Constants/FileCreationSchedule/Daily"
import { monthlyRadioOptions } from "./Constants/FileCreationSchedule/Monthly"
import {
  DayMonthOptions,
  yearlyRadioOptions
} from "./Constants/FileCreationSchedule/Yearly"
import {
  convertUTCToLocalDate,
  getDateAndTimeMerge
} from "./Helpers/CreateEditMultiEventOutput"
import { getTranslations, validateInputString } from "src/utils/helper"

const generalSettingValidation = (
  storeId,
  multiEventOutputsRedux,
  t,
  Translates
) => {
  return yup.object().shape({
    ...outputGeneralSettingValidation(t, Translates).fields,
    [createEditReportFieldNames.Name.name]: yup
      .string()
      .required(requiredErr)
      .test(
        createEditReportFieldNames.Name.name,
        "Multi event name already exist.",
        value => {
          return !multiEventOutputsRedux.some(
            item =>
              item.name === value &&
              Number(storeId) !== Number(item.multiEventId)
          )
        }
      )
      .test(
        createEditReportFieldNames.Name.name,
        `${getTranslations(t, Translates.Invalid_Input)}`,
        value => validateInputString(value ?? "")
      )
  })
}

const getSystemDefaultValidation = () => {
  return yup.object().shape({
    [systemDefaultFieldNames.SDMinStore.name]: yup
      .mixed()
      .when([createEditFieldNames.FileCreationSchedule.name], {
        is: val => val && [fileCSOptions.Default].includes(val),
        then: yup
          .number()
          .typeError(numberTypeErr)
          .required(requiredErr)
          .min(1, "This field cannot be less than 1")
          .test(
            "min-less-than-max",
            "This field cannot be more than Max Store",
            function (min: any) {
              const max: any =
                this.parent[systemDefaultFieldNames.SDMaxStore.name]
              //istanbul ignore next
              return Number(min) <= Number(max) || isNaN(max)
            }
          )
      }),
    [systemDefaultFieldNames.SDMaxStore.name]: yup
      .mixed()
      .when([createEditFieldNames.FileCreationSchedule.name], {
        is: val => val && [fileCSOptions.Default].includes(val),
        then: yup
          .number()
          .typeError(numberTypeErr)
          .required(requiredErr)
          .min(1, "This field cannot be less than 1")
          .test(
            "max-greater-than-min",
            "This field cannot be less than Min Store",
            function (max: any) {
              const min: any =
                this.parent[systemDefaultFieldNames.SDMinStore.name]
              return Number(max) >= Number(min) || isNaN(max)
            }
          )
      })
  })
}

const getDailyValidation = () => {
  return yup.object().shape({
    [dailyFieldNames.DayRadio.name]: yup.string(),
    [dailyFieldNames.DayInterval.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          dailyFieldNames.DayRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Daily].includes(field1) &&
              [dailyRadioOptions.everyDay].includes(field2)
            )
          },
          then: yup
            .number()
            .typeError(numberTypeErr)
            .min(1, "This field cannot be less than 1")
            .required(requiredErr)
        }
      )
  })
}

export const getWeeklyValidation = () => {
  return yup.object().shape({
    [weeklyFieldNames.WeeklyInterval.name]: yup
      .mixed()
      .when([createEditFieldNames.FileCreationSchedule.name], {
        is: field1 => {
          return [fileCSOptions.Weekly].includes(field1)
        },
        then: yup
          .number()
          .typeError(numberTypeErr)
          .min(1, "This field cannot be less than 1")
          .required(requiredErr)
      })
  })
}

export const getMonthlyValidation = () => {
  return yup.object().shape({
    [monthlyFieldNames.DayRadio.name]: yup.string(),
    [monthlyFieldNames.DayNumber.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          monthlyFieldNames.DayRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Monthly].includes(field1) &&
              [monthlyRadioOptions.day].includes(field2)
            )
          },
          then: yup
            .number()
            .typeError(numberTypeErr)
            .required(requiredErr)
            .min(1, "This field cannot be less than 1")
            .max(28, "This field cannot be more than 28")
        }
      ),
    [monthlyFieldNames.DayMonthInterval.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          monthlyFieldNames.DayRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Monthly].includes(field1) &&
              [monthlyRadioOptions.day].includes(field2)
            )
          },
          then: yup.number().typeError(numberTypeErr).required(requiredErr)
        }
      ),
    [monthlyFieldNames.WeekRadio.name]: yup.string(),
    [monthlyFieldNames.WeekNumber.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          monthlyFieldNames.WeekRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Monthly].includes(field1) &&
              [monthlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...WeekNumberOptions.map(item => item.value)], oneOfErr)
        }
      ),
    [monthlyFieldNames.WeekDay.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          monthlyFieldNames.WeekRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Monthly].includes(field1) &&
              [monthlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...WeekDayOptions.map(item => item.value)], oneOfErr)
        }
      ),
    [monthlyFieldNames.WeekMonthInterval.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          monthlyFieldNames.WeekRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Monthly].includes(field1) &&
              [monthlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .number()
            .typeError(numberTypeErr)
            .min(1, "This field cannot be less than 1")
            .required(requiredErr)
        }
      )
  })
}

export const getYearlyValidation = () => {
  return yup.object().shape({
    [yearlyFieldNames.YearInterval.name]: yup
      .mixed()
      .when([createEditFieldNames.FileCreationSchedule.name], {
        is: field1 => {
          return [fileCSOptions.Yearly].includes(field1)
        },
        then: yup
          .number()
          .typeError(numberTypeErr)
          .min(1, "This field cannot be less than 1")
          .required(requiredErr)
      }),
    [yearlyFieldNames.DayMonth.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          yearlyFieldNames.DayMonthRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Yearly].includes(field1) &&
              [yearlyRadioOptions.day].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...DayMonthOptions.map(item => item.value)], oneOfErr)
        }
      ),
    [yearlyFieldNames.DayMonthDayNumber.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          yearlyFieldNames.DayMonthRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Yearly].includes(field1) &&
              [yearlyRadioOptions.day].includes(field2)
            )
          },
          then: () => {
            return yup
              .number()
              .typeError(numberTypeErr)
              .required(requiredErr)
              .min(1, "This field cannot be less than 1")
              .max(31, "This field cannot be more than 31")
              .test("day-less-than-month-max-day", "", function (day: any) {
                const month: any = this.parent[yearlyFieldNames.DayMonth.name]
                //istanbul ignore next
                if (!isNaN(month) && !isNaN(day)) {
                  let maxDay = 31
                  if (
                    [
                      YDMOValues.April,
                      YDMOValues.September,
                      YDMOValues.November
                    ].includes(month)
                  ) {
                    maxDay = 30
                  } else if ([YDMOValues.February].includes(month)) {
                    maxDay = 28
                  }

                  if (maxDay < Number(day)) {
                    return this.createError({
                      path: yearlyFieldNames.DayMonthDayNumber.name,
                      message: `This field cannot be more than ${maxDay}`
                    })
                  }
                }

                return true
              })
          }
        }
      ),
    [yearlyFieldNames.WeekMonthWeekNumber.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          yearlyFieldNames.WeekMonthRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Yearly].includes(field1) &&
              [yearlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...WeekNumberOptions.map(item => item.value)], oneOfErr)
        }
      ),
    [yearlyFieldNames.WeekMonthWeekDay.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          yearlyFieldNames.WeekMonthRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Yearly].includes(field1) &&
              [yearlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...WeekDayOptions.map(item => item.value)], oneOfErr)
        }
      ),
    [yearlyFieldNames.WeekMonth.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          yearlyFieldNames.WeekMonthRadio.name
        ],
        {
          is: (field1, field2) => {
            return (
              [fileCSOptions.Yearly].includes(field1) &&
              [yearlyRadioOptions.week].includes(field2)
            )
          },
          then: yup
            .string()
            .required(requiredErr)
            .oneOf(["", ...DayMonthOptions.map(item => item.value)], oneOfErr)
        }
      )
  })
}

const getDateValidation = storeId => {
  return yup.object().shape({
    [createEditFieldNames.StartDate.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          createEditFieldNames.IsSpecificDateRange.name
        ],
        {
          is: (field1, field2) =>
            [
              fileCSOptions.Default,
              fileCSOptions.Daily,
              fileCSOptions.Weekly
            ].includes(field1) && field2,
          then: () => {
            let validationChain = yup
              .date()
              .typeError(numberTypeErr)
              .required(requiredErr)

            if (storeId === -1) {
              validationChain = validationChain.min(
                new Date().toISOString().split("T")[0],
                "Date should be future"
              )
            }
            validationChain = validationChain.test(
              "start-date-less-than-end-date",
              "This field cannot be more than End Date",
              function (startDate: any) {
                const endDate: any =
                  this.parent[createEditFieldNames.EndDate.name]
                //istanbul ignore next
                return (
                  new Date(endDate) >= new Date(startDate) ||
                  !startDate ||
                  !endDate
                )
              }
            )
            return validationChain
          }
        }
      ),
    [createEditFieldNames.EndDate.name]: yup
      .mixed()
      .when(
        [
          createEditFieldNames.FileCreationSchedule.name,
          createEditFieldNames.IsSpecificDateRange.name
        ],
        {
          is: (field1, field2) =>
            [
              fileCSOptions.Default,
              fileCSOptions.Daily,
              fileCSOptions.Weekly
            ].includes(field1) && field2,
          then: () => {
            let validationChain = yup
              .date()
              .typeError(numberTypeErr)
              .required(requiredErr)
            if (storeId === -1) {
              validationChain = validationChain.min(
                new Date().toISOString().split("T")[0],
                "Date should be future"
              )
            }
            validationChain = validationChain.test(
              "end-date-more-than-start-date",
              "This field cannot be less than Start Date",
              function (endDate: any) {
                const startDate: any =
                  this.parent[createEditFieldNames.StartDate.name]
                //istanbul ignore next
                return (
                  new Date(endDate) >= new Date(startDate) ||
                  !startDate ||
                  !endDate
                )
              }
            )
            return validationChain
          }
        }
      )
  })
}
export const validationSchema = (
  storeId,
  multiEventOutputsRedux,
  t,
  Translates
) => {
  return yup
    .object()
    .shape({
      [createEditFieldNames.ConfigurationIncludes.name]: yup
        .array()
        .min(1, requiredErr)
        .required(requiredErr),
      [createEditFieldNames.Customers.name]: yup
        .array()
        .min(1, requiredErr)
        .required(requiredErr),
      [createEditReportFieldNames.SQL.name]: yup.string().required(requiredErr),
      [createEditReportFieldNames.Columns.name]: yup
        .array()
        .min(1, "At least one column is required")
        .required(requiredErr),
      [createEditFieldNames.FileCreationSchedule.name]: yup
        .string()
        .required(requiredErr)
        .oneOf(
          [
            "",
            ...FcsDetailsFields.fileCreationSchedule.props.formControl.radioGroup.formControlLabels.map(
              item => item.value
            )
          ],
          oneOfErr
        ),

      ...getSystemDefaultValidation().fields,
      ...getDailyValidation().fields,
      ...getWeeklyValidation().fields,
      ...getMonthlyValidation().fields,
      ...getYearlyValidation().fields,

      [createEditFieldNames.Time.name]: yup
        .date()
        .required(requiredErr)
        .min(new Date().toISOString().split("T")[0], "Date should be future"),

      ...getDateValidation(storeId).fields,

      ...destinationValidation().fields,
      ...generalSettingValidation(
        storeId,
        multiEventOutputsRedux,
        t,
        Translates
      ).fields
    })
    .test("at-least-one-field-required", "", function (values) {
      const FileCreationSchedule =
        values[createEditFieldNames.FileCreationSchedule.name]
      if (FileCreationSchedule) {
        if (
          storeId === -1 &&
          [
            fileCSOptions.Default,
            fileCSOptions.Daily,
            fileCSOptions.Weekly
          ].includes(FileCreationSchedule) &&
          values[createEditFieldNames.IsSpecificDateRange.name] &&
          values[createEditFieldNames.Time.name] &&
          values[createEditFieldNames.StartDate.name]
        ) {
          const mergedUSTTime = getDateAndTimeMerge(
            values[createEditFieldNames.Time.name],
            values[createEditFieldNames.StartDate.name]
          )
          const localStartTime = convertUTCToLocalDate(mergedUSTTime)
          //istanbul ignore next
          if (localStartTime < new Date()) {
            return this.createError({
              path: createEditFieldNames.Time.name,
              message: pastTimingErr
            })
          }
        }

        if ([fileCSOptions.Daily].includes(FileCreationSchedule)) {
          if (
            !values[dailyFieldNames.DayRadio.name] &&
            !values[dailyFieldNames.WeekDayRadio.name]
          ) {
            return this.createError({
              path: dailyFieldNames.DayRadio.name,
              message: atLeastOneFieldIsRequired
            })
          }
        } else if ([fileCSOptions.Weekly].includes(FileCreationSchedule)) {
          if (
            !values[weeklyFieldNames.Sunday.name] &&
            !values[weeklyFieldNames.Monday.name] &&
            !values[weeklyFieldNames.Tuesday.name] &&
            !values[weeklyFieldNames.Wednesday.name] &&
            !values[weeklyFieldNames.Thursday.name] &&
            !values[weeklyFieldNames.Friday.name] &&
            !values[weeklyFieldNames.Saturday.name]
          ) {
            return this.createError({
              path: weeklyFieldNames.Sunday.name,
              message: atLeastOneFieldIsRequired
            })
          }
        } else if ([fileCSOptions.Monthly].includes(FileCreationSchedule)) {
          if (
            !values[monthlyFieldNames.DayRadio.name] &&
            !values[monthlyFieldNames.WeekRadio.name]
          ) {
            return this.createError({
              path: monthlyFieldNames.DayRadio.name,
              message: atLeastOneFieldIsRequired
            })
          }
        } else if ([fileCSOptions.Yearly].includes(FileCreationSchedule)) {
          if (
            !values[yearlyFieldNames.DayMonthRadio.name] &&
            !values[yearlyFieldNames.WeekMonthRadio.name]
          ) {
            return this.createError({
              path: yearlyFieldNames.DayMonthRadio.name,
              message: atLeastOneFieldIsRequired
            })
          }
        }
      }

      return true
    })
}
