import { formField } from "src/utils/constants"
import {
  ConfigurationDetailsFieldsType,
  MultiEventOutputFormType
} from "../Types"
import moment from "moment"

export const createEditFieldNames = {
  Customers: {
    name: "Customers",
    label: "Customers"
  },
  FileCreationSchedule: {
    name: "FileCreationSchedule",
    label: "FileCreationSchedule"
  },
  ConfigurationIncludes: {
    name: "ConfigurationIncludes",
    label: "Configurations_To_Include"
  },
  IsSpecificDateRange: {
    name: "IsSpecificDateRange",
    label: "Specific_Date_Range"
  },
  StartDate: {
    name: "StartDate",
    label: "Start_Date"
  },
  EndDate: {
    name: "EndDate",
    label: "End_Date"
  },
  Time: {
    name: "Time",
    label: "Time"
  },
  Loadings: {
    name: "Loadings",
    label: "Loadings"
  }
}

export const systemDefaultFieldNames = {
  SDMinStore: { name: "SystemDefaultMinStore", label: "" },
  SDMaxStore: { name: "SystemDefaultMaxStore", label: "" }
}

export const dailyFieldNames = {
  DayRadio: { name: "DailyDayRadio", label: "" },
  DayInterval: { name: "DailyDayInterval", label: "" },
  WeekDayRadio: { name: "DailyWeekDayRadio", label: "Every_Weekday" }
}

export const weeklyFieldNames = {
  WeeklyInterval: { name: "WeeklyInterval", label: "" },
  IsWeekDays: { name: "WeeklyIsWeekDays", label: "Weekday" },
  Sunday: { name: "WeeklySunday", label: "Sunday" },
  Monday: { name: "WeeklyMonday", label: "Monday" },
  Tuesday: { name: "WeeklyTuesday", label: "Tuesday" },
  Wednesday: { name: "WeeklyWednesday", label: "Wednesday" },
  Thursday: { name: "WeeklyThursday", label: "Thursday" },
  Friday: { name: "WeeklyFriday", label: "Friday" },
  Saturday: { name: "WeeklySaturday", label: "Saturday" }
}

export const monthlyFieldNames = {
  DayRadio: { name: "MonthlyDayRadio", label: "" },
  DayNumber: { name: "MonthlyDayNumber", label: "" },
  DayMonthInterval: { name: "MonthlyDayMonthInterval", label: "" },
  WeekRadio: { name: "MonthlyWeekRadio", label: "" },
  WeekNumber: { name: "MonthlyWeekNumber", label: "" },
  WeekDay: { name: "MonthlyWeekDay", label: "" },
  WeekMonthInterval: { name: "MonthlyWeekMonthInterval", label: "" }
}

export const yearlyFieldNames = {
  YearInterval: { name: "YearlyYearInterval", label: "" },
  DayMonthRadio: { name: "YearlyDayMonthRadio", label: "" },
  DayMonth: { name: "YearlyDayMonth", label: "" },
  DayMonthDayNumber: { name: "YearlyDayMonthDayNumber", label: "" },
  WeekMonthRadio: { name: "YearlyWeekMonthRadio", label: "" },
  WeekMonthWeekNumber: { name: "YearlyWeekMonthWeekNumber", label: "" },
  WeekMonthWeekDay: { name: "YearlyWeekMonthWeekDay", label: "" },
  WeekMonth: { name: "YearlyWeekMonth", label: "" }
}

export const MDWNOValues = {
  First: "1",
  Second: "2",
  Third: "3",
  Fourth: "4"
}
export const weekDays = {
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday"
}

export const YDMOValues = {
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December"
}

export const ConfigurationDetailsFields: ConfigurationDetailsFieldsType = {
  configurationIncludes: {
    fieldType: formField.multi_auto_dropdown,
    props: {
      name: createEditFieldNames.ConfigurationIncludes.name,
      label: createEditFieldNames.ConfigurationIncludes.label,
      options: [],
      required: true
    }
  }
}

export const createEditMultiEventOutputDefaultValues: MultiEventOutputFormType =
  {
    RecordId: -1,
    Customers: [],
    ConfigurationIncludes: [],
    //General settings start
    Name: "",
    Description: "",
    FileName: "",
    FileType: "",
    FieldDelimiter: "",
    OtherFieldDelimiter: "",
    RecordDelimiter: "",
    OtherRecordDelimiter: "",
    AsciiCharValue: "",
    HasHeaderRecord: false,
    //General settings end
    RawSQL: "",
    SQL: "",
    Columns: [],
    ColumnsToSuppress: 0,
    SubTotals: [],
    GrandTotals: [],
    //File creation schedule fields start
    FileCreationSchedule: "",

    //System Default radio option fields start
    SystemDefaultMinStore: "",
    SystemDefaultMaxStore: "",
    //System Default fields end

    //Daily radio option fields start
    DailyDayRadio: "",
    DailyDayInterval: "",
    DailyWeekDayRadio: "",
    //Daily radio option fields end

    //Weekly radio option fields start
    WeeklyInterval: "",
    WeeklyIsWeekDays: false,
    WeeklySunday: false,
    WeeklyMonday: false,
    WeeklyTuesday: false,
    WeeklyWednesday: false,
    WeeklyThursday: false,
    WeeklyFriday: false,
    WeeklySaturday: false,
    //Weekly radio option fields end

    //Monthly radio option fields start
    MonthlyDayRadio: "",
    MonthlyDayNumber: "",
    MonthlyDayMonthInterval: "",
    MonthlyWeekRadio: "",
    MonthlyWeekNumber: MDWNOValues.First,
    MonthlyWeekDay: weekDays.Wednesday,
    MonthlyWeekMonthInterval: "",
    //Monthly radio option fields end

    //Yearly radio option fields start
    YearlyYearInterval: "",
    YearlyDayMonthRadio: "",
    YearlyDayMonth: YDMOValues.April,
    YearlyDayMonthDayNumber: "",
    YearlyWeekMonthRadio: "",
    YearlyWeekMonthWeekNumber: MDWNOValues.First,
    YearlyWeekMonthWeekDay: weekDays.Wednesday,
    YearlyWeekMonth: YDMOValues.April,
    //Yearly radio option fields end

    Time: moment(new Date()).add(1, "hours").toDate(),
    IsSpecificDateRange: false,
    StartDate: new Date(),
    EndDate: new Date(),
    //File creation schedule radio option fields end
    Destination: {
      CustomerPortalCheck: true,
      FtpDirectory: [],
      Email: []
    },
    Loadings: { isGetAPICall: true, isConfigAPICall: true }
  }
