import { Grid, Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import {
  encrypt,
  filterByHelper,
  filterRegionalUserData,
  getCurrentUserRole,
  getTranslations,
  handleSuperVisorPortalRedirect,
  mapEventsData,
  _userRole,
  decrypt
} from "src/utils/helper"
import { useCallback, useEffect, useState } from "react"
import { Translates } from "src/i18n/i18n"
import { useTranslation } from "react-i18next"
import LiveEventsFilter from "./LiveEventsFilter"
import { RootState, useAppThunkDispatch } from "src/redux/store"
import InfoIcon from "@mui/icons-material/Info"
import { ScheduleService } from "../../../src/service/schedule.service"
import {
  DEFAULT_RADIX,
  KEY_CODES,
  LIVE_EVENTS_STATUS_CONSTANTS,
  LIVE_EVENTS_STATUS_CONSTANTS_ORDERS,
  ROLE_CONSTANTS
} from "src/utils/constants"
import { SettingsService } from "src/service/settings.service"
import EventItem from "./EventItem"
import moment from "moment"
import { setLiveEventsSelectedSpans } from "src/redux/slices/liveEventsSlice"
import { MAP_FILTERS } from "src/configurationPortal/pages/Variances/Utils/Constants"

const EventTiles = ({
  showFilterPage,
  hideFilter,
  sendFilterDataToParent,
  response,
  tab,
  eventsData,
  searched,
  pollingCount
}) => {
  const { t } = useTranslation<any>()

  const [data, setData] = useState(eventsData || [])
  const [storeData, setStoreData] = useState({} as any)
  let regionalGroupsInfo: any
  const liveEventsFilterForm = useSelector(
    (state: RootState) => state.liveEvents?.liveEventsFilterForm || {}
  )
  const liveEventsSelectedSpans = useSelector(
    (state: RootState) => state.liveEvents?.liveEventsSelectedSpans || []
  )
  const [selectedSpans, setSelectedSpans] = useState([] as any)
  const customerList = useSelector(
    (state: RootState) => state.settings?.customers
  )
  const dispatch = useAppThunkDispatch()
  const selectedCustomerList = customerList?.filter((c: any) => c.selected)
  const multipleCustomersAssigned = selectedCustomerList?.length > 1
  const [countries, setCountries] = useState([] as any)
  const [states, setStates] = useState([] as any)
  const [status, setStatus] = useState([] as any)
  const [eventStartDate, setEventStartDate] = useState("" as any)
  const customerId = useSelector(
    (state: RootState) => state.settings?.selectedCustomerForOperation
  )
  const settingsService = new SettingsService()
  const [prevData, setPrevData] = useState([] as any)
  const [mainFilterApplied, setMainFilterApplied] = useState(false)
  const scheduleService = new ScheduleService()
  useEffect(() => {
    setSelectedSpans(liveEventsSelectedSpans)
  }, [])
  useEffect(() => {
    if (customerId) {
      init()
    }
  }, [customerId])
  useEffect(() => {
    if (mainFilterApplied) {
      const hasFilters = hasFilterValues()
      if (hasFilters) {
        getAppliedValues(liveEventsFilterForm)
      }
    }
  }, [mainFilterApplied])
  useEffect(() => {
    const handleOutsideClick = event => {
      if (event.target.id !== "filterButton" || event.target.id === "") {
        if (!mainFilterApplied) {
          setSelectedSpans([])
          setData(eventsData)
        }
      }
    }
    document.addEventListener("click", handleOutsideClick)
    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [mainFilterApplied])
  const hasFilterValues = () => {
    const hasFilters =
      Object.keys(liveEventsFilterForm).length &&
      (liveEventsFilterForm.country?.length > 0 ||
        liveEventsFilterForm.state?.length > 0 ||
        liveEventsFilterForm.currentDate !== "" ||
        liveEventsFilterForm.region1?.length > 0 ||
        liveEventsFilterForm.region2?.length > 0 ||
        liveEventsFilterForm.region3?.length > 0 ||
        liveEventsFilterForm.region4?.length > 0 ||
        liveEventsFilterForm.eventStatus?.length > 0)
    return hasFilters
  }

  useEffect(() => {
    const hasFilters = hasFilterValues()
    if (hasFilters) {
      getAppliedValues(liveEventsFilterForm)
    }
    setSelectedSpans(liveEventsSelectedSpans)
  }, [liveEventsFilterForm, pollingCount, liveEventsSelectedSpans])

  const getAppliedValues = filteredData => {
    sendFilterDataToParent(filteredData)
    const filters: any = []

    if (filteredData.country?.length > 0) {
      filters.push({
        field: "country",
        value: filteredData.country,
        func: "equalString"
      })
    }

    if (filteredData.state?.length > 0) {
      filters.push({
        field: "state",
        value: filteredData.state,
        func: "equalString"
      })
    }

    if (filteredData.currentDate) {
      filters.push({
        field: "eventStartDate",
        value: moment(filteredData?.currentDate)?.format("YYYY-MM-DD"),
        func: "equalDate"
      })
    }

    if (filteredData.eventStatus?.length > 0) {
      filters.push({
        field: "status",
        value: filteredData.eventStatus,
        func: "equals"
      })
    }

    const regionFields = ["region1", "region2", "region3", "region4"]
    regionFields.forEach(field => {
      if (filteredData[field]?.length > 0) {
        filters.push({
          field: field,
          value: filteredData[field],
          func: "equals"
        })
      }
    })

    setData(oldVal => {
      const filtered = filterByHelper(filters, oldVal)
      setPrevData(filtered)
      setMainFilterApplied(filtered ? true : false)
      return filtered
    })
  }
  const init = () => {
    if (customerId) {
      getLocationsByStore().then(resp => {
        setStoreData(resp.data)
      })
    }
    regionalGroupsInfo =
      getCurrentUserRole("USER_REGIONS") &&
      JSON.parse(getCurrentUserRole("USER_REGIONS"))
    if (
      regionalGroupsInfo?.length > 0 &&
      _userRole?.toLowerCase() === ROLE_CONSTANTS.RegionalUser.toLowerCase()
    ) {
      const regionalEventsData = filterRegionalUserData(
        eventsData,
        regionalGroupsInfo
      )
      setData(sortData(regionalEventsData))
      let eventDetails: any = mapEventsData(regionalEventsData)
      setCountries(eventDetails.countryValues)
      setStates(eventDetails.stateValues)
      setStatus(eventDetails.statusValues)
      setEventStartDate(eventDetails.eventStartDateValues)
    } else {
      setData(sortData(eventsData))
      let eventDetails = mapEventsData(eventsData)
      setCountries(eventDetails.countryValues)
      setStates(eventDetails.stateValues)
      setStatus(eventDetails.statusValues)
      setEventStartDate(eventDetails.eventStartDateValues)
    }
  }
  const getLocationsByStore = () => {
    return settingsService.getLocationsByStore([customerId])
  }

  const handleRedirect = record => {
    //redirect to supervisor portal
    scheduleService
      .getEventPasswords(record.eventId)
      .then(res => {
        const decyptedResp = JSON.parse(decrypt(res.data))

        record["password"] = encrypt(decyptedResp?.SupervisorPassword)
        handleSuperVisorPortalRedirect(record, "liveEvents")
      })
      .catch(_err => {
        console.error(_err)
      })
  }
  const isSpanSelected = spanId => selectedSpans.includes(spanId)
  const handleClick = (e, id) => {
    if (e.ctrlKey || e.metaKey) {
      if (id === 0) {
        displayDefaultMessage()
      }
      // Ctrl + click: Toggle the selection
      setSelectedSpans(prevSelectedFilters => {
        if (prevSelectedFilters.includes(id)) {
          filterDataOnSpanClick(
            prevSelectedFilters.filter(selectedFilter => selectedFilter !== id),
            e
          )
          dispatch(
            setLiveEventsSelectedSpans(prevSelectedFilters =>
              prevSelectedFilters.filter(
                selectedFilter => selectedFilter !== id
              )
            )
          )
          return prevSelectedFilters.filter(
            selectedFilter => selectedFilter !== id
          )
        } else {
          filterDataOnSpanClick([...prevSelectedFilters, id], e)
          const newSelectedFilters = [...prevSelectedFilters, id]
          dispatch(setLiveEventsSelectedSpans(newSelectedFilters))
          return [...prevSelectedFilters, id]
        }
      })
    } else {
      if (selectedSpans.includes(id)) {
        // Clicking on an already selected span: Deselect and update data
        setSelectedSpans(prevSelectedFilters =>
          prevSelectedFilters.filter(selectedFilter => selectedFilter !== id)
        )
        filterDataOnSpanClick(
          selectedSpans.filter(selectedFilter => selectedFilter !== id),
          e
        )
      } else {
        filterDataOnSpanClick([id], e)
        dispatch(setLiveEventsSelectedSpans([id]))
        // Single click: Select the filter and clear other selections
        setSelectedSpans([id])
      }
    }
  }
  const handleKeyUp = useCallback((e: any, id) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      handleClick(e, id)
    }
  }, [])
  const getNewData = () => {
    if (prevData.length) {
      return prevData
    } else {
      if (mainFilterApplied) {
        return data
      } else {
        return eventsData
      }
    }
  }
  const filterDataOnSpanClick = (sp, e) => {
    const newData = getNewData()
    if (sp.length === 0) {
      setData(newData)
      return
    }

    const filteredData: any = []
    const applyFilterForSpans = filterFn => {
      filterFn.forEach(item => {
        if (!filteredData.some(obj => obj.eventId === item.eventId)) {
          filteredData.push(item)
        }
      })
    }
    const filters = [
      {
        index: 1,
        filterFn: newData.filter(item => item.countProgress === 0)
      },
      {
        index: 2,
        filterFn: newData.filter(
          item => item.countProgress > 0 && item.countProgress < 100
        )
      },
      {
        index: 3,
        filterFn: newData.filter(item => item.countProgress === 100)
      },
      {
        index: 4,
        filterFn: newData.filter(item => item.auditProgress === 0)
      },
      {
        index: 5,
        filterFn: newData.filter(
          item => item.auditProgress > 0 && item.auditProgress < 100
        )
      },
      {
        index: 6,
        filterFn: newData.filter(item => item.auditProgress === 100)
      },
      {
        index: 7,
        filterFn: newData.filter(item => item.varianceProgress === 0)
      },
      {
        index: 8,
        filterFn: newData.filter(
          item =>
            item.varianceProgress >= 0 &&
            item.varianceProgress < 100 &&
            item.isVarianceCreated
        )
      },
      {
        index: 9,
        filterFn: newData.filter(item => item.varianceProgress === 100)
      }
    ]
    filters.forEach(filter => {
      if (sp.includes(filter.index)) {
        applyFilterForSpans(filter.filterFn)
      }
    })
    setData(filteredData)
  }

  const getDaysCount = () => {
    // Get the days events count to show on tabs
    switch (tab) {
      case 0:
        return response.yesterdayEventsCount
      case 1:
        return response.todayEventsCount
      default:
        return response.tomorrowEventsCount
    }
  }

  const showVarianceProgress = () => {
    const eventsDetails = response?.eventsDetails || []
    const hasTrueFlag = eventsDetails.some(
      evt => evt.varianceConfiguredFlag === true
    )
    const {
      [LIVE_EVENTS_STATUS_CONSTANTS?.NotStarted]: notStartedCount = 0,
      [LIVE_EVENTS_STATUS_CONSTANTS?.InProgress]: inProgressCount = 0,
      [LIVE_EVENTS_STATUS_CONSTANTS?.Closed]: closedCount = 0
    } = varianceStatusCounts || {}

    return hasTrueFlag && (notStartedCount || inProgressCount || closedCount)
  }
  // Sorting of tiles depending on 1. status("not started","in progress","closed") 1. custName 3. siteId
  const sortData = dataToSort => {
    const sortedData =
      dataToSort &&
      dataToSort?.sort((a, b) => {
        const statusA = LIVE_EVENTS_STATUS_CONSTANTS_ORDERS?.indexOf(
          a.status?.trim().toLowerCase()
        )
        const statusB = LIVE_EVENTS_STATUS_CONSTANTS_ORDERS?.indexOf(
          b.status?.trim()?.toLowerCase()
        )

        return (
          statusA - statusB ||
          (a.customerName ?? "").localeCompare(b.customerName ?? "") ||
          parseInt(a.siteId, DEFAULT_RADIX) - parseInt(b.siteId, DEFAULT_RADIX)
        )
      })
    return sortedData
  }
  const getData = () => {
    if (selectedSpans.length) {
      if (prevData.length) {
        return prevData
      } else {
        return eventsData
      }
    } else {
      return data
    }
  }
  const getCountStatusCounts = () => {
    const eventsDetails = getData() || []
    const countStatusCounts = eventsDetails?.reduce((counts, item) => {
      const { status, countProgress } = item
      if (countProgress > 0 && countProgress < 100) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] || 0) + 1
      } else if (countProgress === 0) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] || 0) + 1
      } else if (countProgress === 100) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] || 0) + 1
      } else {
        counts[status] = (counts[status] || 0) + 1
      }
      return counts
    }, {})

    return countStatusCounts
  }
  const getAuditStatusCounts = () => {
    const eventsDetails = getData() || []
    const auditStatusCounts = eventsDetails.reduce((counts, item) => {
      const { status, auditProgress, countProgress } = item
      if (auditProgress > 0 && auditProgress < 100) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] || 0) + 1
      } else if (auditProgress === 0) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] || 0) + 1
      } else if (auditProgress === 100 && countProgress === 100) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] || 0) + 1
      } else {
        counts[status] = (counts[status] || 0) + 1
      }
      return counts
    }, {})
    return auditStatusCounts
  }
  const getVarianceStatusCounts = () => {
    const eventsDetails = getData() || []
    const varianceStatusCounts = eventsDetails.reduce((counts, item) => {
      const { status, varianceProgress, isVarianceCreated } = item
      if (
        varianceProgress >= 0 &&
        varianceProgress < 100 &&
        isVarianceCreated
      ) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] || 0) + 1
      } else if (varianceProgress === 0 && !isVarianceCreated) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] || 0) + 1
      } else if (varianceProgress === 100) {
        counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] =
          (counts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] || 0) + 1
      } else {
        counts[status] = (counts[status] || 0) + 1
      }
      return counts
    }, {})
    return varianceStatusCounts
  }

  let countStatusCounts: any = getCountStatusCounts()
  let auditStatusCounts: any = getAuditStatusCounts()
  let varianceStatusCounts: any = getVarianceStatusCounts()

  const cancelClick = () => {
    hideFilter()
  }
  const getWidth = () => {
    if (mainFilterApplied) {
      return data.length
    } else if (searched) {
      return eventsData.length
    } else {
      return getDaysCount()
    }
  }
  const displayDefaultMessage = () => {
    return (
      <div className="d-flex f-600 justify-content-center">
        <InfoIcon className="mr-1" />
        {getTranslations(t, Translates.No_Events_Found)}
      </div>
    )
  }
  // Checking for if already having selected span
  const compositeFilterFn = item => {
    // Find all filter functions for the selected spans
    const filterFns = selectedSpans
      .map(span => MAP_FILTERS?.find(f => f.index === span)?.filterFn)
      .filter(fn => fn) // Remove undefined filter functions

    // If there are no valid filter functions, return true to include all items
    if (filterFns.length === 0) return true

    // Check if the item satisfies at least one filter function
    return filterFns?.some(fn => fn(item))
  }
  return (
    <div id="filterButton">
      {Object.keys(response).length > 0 && data.length > 0 ? (
        <>
          <div className="gray-bg py-2 px-7 mb-3">
            <Grid container spacing={2} id="filterButton">
              {countStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] ||
              countStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] ||
              countStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] ? (
                <Grid item xs={12} sm={4}>
                  <h6 className="font-14">
                    {getTranslations(t, Translates.Count_Progress)}
                  </h6>
                  <div className="progress-row" style={{ display: "flex" }}>
                    {/* Closed */}
                    {countStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(3)
                              ? "selected completed cursor-pointer"
                              : "completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 3)}
                          onKeyUp={e => handleKeyUp(e, 3)}
                          style={{
                            width:
                              (countStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.Closed
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            countStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.Closed
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className="completed cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* In Progress */}
                    {countStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(2)
                              ? "selected in-progress light cursor-pointer"
                              : "in-progress light cursor-pointer"
                          }
                          onClick={e => handleClick(e, 2)}
                          onKeyUp={e => handleKeyUp(e, 2)}
                          style={{
                            width:
                              (countStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            countStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className="in-progress light cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* Not Started */}
                    {countStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(1)
                              ? "selected not-completed cursor-pointer"
                              : "not-completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 1)}
                          onKeyUp={e => handleKeyUp(e, 1)}
                          style={{
                            width:
                              (countStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            countStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className="not-completed cursor-pointer"
                          style={{ width: "auto" }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Grid>
              ) : null}
              {auditStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.NotStarted] ||
              auditStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.InProgress] ||
              auditStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] ? (
                <Grid item xs={12} sm={4}>
                  <h6 className="font-14">
                    {getTranslations(t, Translates.Audit_Progress)}
                  </h6>
                  <div
                    className="progress-row audit"
                    style={{ display: "flex" }}
                  >
                    {/* Closed */}
                    {auditStatusCounts[LIVE_EVENTS_STATUS_CONSTANTS.Closed] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(6)
                              ? "selected completed cursor-pointer"
                              : "completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 6)}
                          onKeyUp={e => handleKeyUp(e, 6)}
                          style={{
                            width:
                              (auditStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.Closed
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            auditStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.Closed
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className="completed cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* In Progress */}
                    {auditStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(5)
                              ? "selected in-progress light cursor-pointer"
                              : "in-progress light cursor-pointer"
                          }
                          onClick={e => handleClick(e, 5)}
                          onKeyUp={e => handleKeyUp(e, 5)}
                          style={{
                            width:
                              (auditStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            auditStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className="in-progress light cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* Not Started */}
                    {auditStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(4)
                              ? "selected not-completed cursor-pointer"
                              : "not-completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 4)}
                          onKeyUp={e => handleKeyUp(e, 4)}
                          style={{
                            width:
                              (auditStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            auditStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className="not-completed cursor-pointer"
                          style={{ width: "auto" }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Grid>
              ) : null}
              {showVarianceProgress() ? (
                <Grid item xs={12} sm={4}>
                  <h6 className="font-14">
                    {getTranslations(t, Translates.Variance_Progress)}
                  </h6>
                  <div
                    className="progress-row variance"
                    style={{ display: "flex" }}
                  >
                    {/* Closed */}
                    {varianceStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.Closed
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(9)
                              ? "selected completed cursor-pointer"
                              : "completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 9)}
                          onKeyUp={e => handleKeyUp(e, 9)}
                          style={{
                            width:
                              (varianceStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.Closed
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            varianceStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.Closed
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Completed)}
                      >
                        <span
                          id="filterButton"
                          className="completed cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* In Progress */}
                    {varianceStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(8)
                              ? "selected in-progress light cursor-pointer"
                              : "in-progress light cursor-pointer"
                          }
                          onClick={e => handleClick(e, 8)}
                          onKeyUp={e => handleKeyUp(e, 8)}
                          style={{
                            width:
                              (varianceStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            varianceStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.InProgress
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.In_Progress)}
                      >
                        <span
                          id="filterButton"
                          className="in-progress light cursor-pointer"
                          style={{
                            width: "auto"
                          }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                    {/* Not Started */}
                    {varianceStatusCounts[
                      LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                    ] ? (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className={
                            isSpanSelected(7)
                              ? "selected not-completed cursor-pointer"
                              : "not-completed cursor-pointer"
                          }
                          onClick={e => handleClick(e, 7)}
                          onKeyUp={e => handleKeyUp(e, 7)}
                          style={{
                            width:
                              (varianceStatusCounts[
                                LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                              ] /
                                getWidth()) *
                                100 +
                              "%"
                          }}
                        >
                          {
                            varianceStatusCounts[
                              LIVE_EVENTS_STATUS_CONSTANTS.NotStarted
                            ]
                          }
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        title={getTranslations(t, Translates.Not_Started)}
                      >
                        <span
                          id="filterButton"
                          className="not-completed cursor-pointer"
                          style={{ width: "auto" }}
                          onClick={e => handleClick(e, 0)}
                        >
                          0
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
          {data?.length ? (
            <Grid
              container
              className="events-list"
              spacing={2}
              id="filterButton"
            >
              {data?.filter(compositeFilterFn)?.map((event: any) => (
                <EventItem
                  key={event.eventId}
                  event={event}
                  handleRedirect={handleRedirect}
                  t={t}
                  Translates={Translates}
                />
              ))}
            </Grid>
          ) : (
            displayDefaultMessage()
          )}
        </>
      ) : (
        displayDefaultMessage()
      )}
      {showFilterPage ? (
        <LiveEventsFilter
          storeData={storeData}
          userData={data}
          cancelClick={cancelClick}
          applyClick={getAppliedValues}
          countries={countries}
          states={states}
          status={status}
          showFilterPage={showFilterPage}
          currentDate={eventStartDate}
          multipleCustomersAssigned={multipleCustomersAssigned}
        />
      ) : null}
    </div>
  )
}

export default EventTiles
