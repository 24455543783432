import { formField } from "src/utils/constants"
import { renderType } from "./FcsOptions"
import { WeeklyFields1 } from "../../Types"
import { weeklyFieldNames } from "../CreateEditMultiEventOutput"

export const weeklyFields = [
  [
    { type: renderType.heading, heading: "Every", className: "ml-1" },
    {
      type: renderType.field,
      fieldType: formField.text,
      width: "80px",
      className: "ml-2",

      props: {
        name: weeklyFieldNames.WeeklyInterval.name,
        label: weeklyFieldNames.WeeklyInterval.label,
        required: true
      }
    },
    { type: renderType.heading, heading: "week_s", className: "ml-2" }
  ],
  [
    {
      type: renderType.field,
      fieldType: formField.checkbox,
      className: "mt-2",
      props: {
        name: weeklyFieldNames.IsWeekDays.name,
        label: weeklyFieldNames.IsWeekDays.label,
        className: "light"
      },
      resetFieldNames: [
        { name: weeklyFieldNames.Sunday.name, value: false },
        { name: weeklyFieldNames.Saturday.name, value: false }
      ]
    }
  ]
]

export const weeklyFields1: WeeklyFields1 = {
  sunday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Sunday.name,
      label: weeklyFieldNames.Sunday.label,
      className: "light"
    },
    disableCondition: {
      name: weeklyFieldNames.IsWeekDays.name,
      value: false
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  monday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Monday.name,
      label: weeklyFieldNames.Monday.label,
      className: "light"
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  tuesday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Tuesday.name,
      label: weeklyFieldNames.Tuesday.label,
      className: "light"
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  wednesday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Wednesday.name,
      label: weeklyFieldNames.Wednesday.label,
      className: "light"
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  thursday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Thursday.name,
      label: weeklyFieldNames.Thursday.label,
      className: "light"
    },
    isNewRow: true,
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  friday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Friday.name,
      label: weeklyFieldNames.Friday.label,
      className: "light"
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  },
  saturday: {
    fieldType: formField.checkbox,
    props: {
      name: weeklyFieldNames.Saturday.name,
      label: weeklyFieldNames.Saturday.label,
      className: "light"
    },
    disableCondition: {
      name: weeklyFieldNames.IsWeekDays.name,
      value: false
    },
    parentCols: { sm: 2 },
    childCols: { sm: 12 },
    triggerValidationFields: [
      {
        name: weeklyFieldNames.Sunday.name
      }
    ]
  }
}
